import {FILE_UPLOAD_OPERATION_ALL_FILES_DIALOG, useAppDispatch, useAppState} from "../app/AppContext";
import React, {useCallback, useEffect, useState} from "react";
import Box from "@mui/material/Box";
import LinearProgress from "@mui/material/LinearProgress";
import Button from "@mui/material/Button";
import {cancelOperation} from "./cancelOperation";
import {clientLog} from "../clientLog";
import {DialogListFiles} from "../damsfileupload3/metadata/DialogListFiles";
import Typography from "@mui/material/Typography";
import {showSnackbar} from "../showSnackbar";
import {useSnackbarDispatch} from "../snackbar/SnackbarContext";
import {FileConversionStatus} from "./FileConversionStatus";

/**
 * A React component used to display a list of ongoing file upload operations.
 *
 * This component subscribes to the list of operations stored in the app state and
 * displays a box with information about each file upload operation. The box
 * will display the number of files being uploaded, the progress of the upload and
 * a button to view the uploaded files when the upload is complete.
 *
 * If an operation has failed, the box will display the error message and a button
 * to cancel the operation.
 *
 * @return {JSX.Element} The JSX element representing the box with information about the ongoing file upload operations.
 */
export const OperationsPopper = () => {
    const {operations} = useAppState();
    const appDispatch = useAppDispatch();
    const snackbarDispatch = useSnackbarDispatch();

    const [showActiveOperations, setShowActiveOperations] = useState(false);
    const [activeOperations, setActiveOperations] = useState([]);

    const [viewedFileOperations, setViewedFileOperations] = useState([]);


    /**
     * Handles the click event for the "Vis alle filer" button.
     *
     * When the button is clicked, the `fileUploadOperationAllFilesDialog` action is dispatched with the operation ID and the `open` property set to `true`.
     * This will open the dialog showing all files for the operation.
     *
     * @private
     */
    const handleViewAllFilesClick = (operation, lastUpdate) => {
        if (!operation) {
            return;
        }
        const {jobId} = operation;
        if (!jobId) {
            return;
        }

        const failedConversion = lastUpdate?.details?.failed.map(failed => (failed['dms_id']));

        setViewedFileOperations([...viewedFileOperations, jobId]);
        appDispatch({
            type: FILE_UPLOAD_OPERATION_ALL_FILES_DIALOG,
            data: {
                open: true,
                operationId: jobId,
                failedConversion: failedConversion
            }
        });
    };

    /**
     * Toggles the visibility of the list of active operations.
     *
     * If the list is visible, this will hide it and vice versa.
     *
     * @private
     */
    const toggleOperationsList = () => {
        setShowActiveOperations(!showActiveOperations);
    };

    /**
     * Handles the click event for the "Lukk" button on the file upload operation error dialog.
     *
     * When the button is clicked, the `CANCEL_OPERATION` action is dispatched with the operation to cancel.
     * This will cancel the operation and remove it from the list of operations.
     *
     * @private
     */
    const handleErrorClick = (operation) => {
        cancelOperation(appDispatch, operation, (cancelledOperation) => {
            clientLog('info', `operation: ${cancelledOperation['jobId']} - cancelled by the user`, 'fileuploadoperationactionbuttons');
        });
    };

    /**
     * Checks if there are any worker messages present for any of the operations.
     *
     * @returns {boolean} True if there are any worker messages present, false otherwise.
     */
    const isWorkerMessagesPresent = () => {
        if (!operations || operations.length === 0) {
            return false;
        }
        return operations.some(o => o.workerMessages && o.workerMessages.length > 0);
    };

    /**
     * Returns a list of operations that match the given operation type.
     *
     * @param {Array} operations - The list of operations to filter.
     * @param {string} operationType - The type of operation to filter by.
     * @returns {Array} A list of operations that match the given operation type.
     */
    const getOperationsByType = (operations, operationType) => {
        if (!operations || operations.length === 0) {
            return [];
        }
        return operations.filter(o => o.jobType === operationType);
    };

    /**
     * Returns the last message sent from the worker for the given operation.
     *
     * If the operation has no worker messages, an empty object is returned.
     *
     * @param {object} operation - The operation to get the last worker message for.
     * @param {Array} workerMessages - The list of worker messages for the given operation.
     * @returns {object} The last worker message sent from the worker, or an empty object if no messages are present.
     */
    const getOperationLastUpdate = (operation, workerMessages) => {
        if (!workerMessages || workerMessages.length === 0) {
            return {};
        }

        const lastMessage = workerMessages[workerMessages.length - 1];
        if (!lastMessage || !lastMessage.details) {
            return {};
        }
        return lastMessage;
    };

    /**
     * Returns a human-readable title for the given stage ID.
     *
     * @param {string} stageId - The ID of the stage to get the human-readable title for.
     * @returns {string} A human-readable title for the given stage ID, or the original stage ID if no matching title is found.
     */
    const getStageHrTitle = (stageId) => {
        switch (stageId) {
            case 'savingdamsobjects':
                return 'oppretter DAMS objekter';
            case 'creatingdamsobjects':
                return 'oppretter DAMS objekter';
            case 'gettingobjectstatus':
                return '';
            case 'extractingmetadata':
                return 'henter metadata';
            default:
                return stageId;
        }
    };

    const getStatusHr = status => {
        switch (status) {
            case 'converting':
                return 'konverterer';
            case 'init':
                return 'initialiserer';
            case 'done':
                return 'ferdig';
            default:
                return '';
        }
    };

    /**
     * Returns the progress of a file upload operation, given the list of files and the last update message.
     *
     * If the last update message does not contain the key `details`, the progress is considered to be 0.
     *
     * The progress is calculated as the number of processed files divided by the total number of files in the operation, multiplied by 100.
     *
     * @param {Array} files - The list of files in the operation.
     * @param {object} lastUpdate - The last update message sent from the worker.
     * @returns {number} The progress of the file upload operation, as a percentage.
     */
    const getProgress = (files, lastUpdate) => {
        if (!Object.keys(lastUpdate).includes('details')) {
            return 0;
        }
        let processed;
        if (Object.keys(lastUpdate.details).includes('failed') && Object.keys(lastUpdate.details).includes('success')) {
            processed = lastUpdate.details.failed.length + lastUpdate.details.success.length;
        } else {
            const {statuses = []} = lastUpdate.details;
            processed = statuses.filter(s => (s.status === 'done' || s.status === 'error' || s.status === 'failed'))?.length || 0;
        }
        return (processed / files.length) * 100;
    };


    /**
     * Returns a human-readable representation of the status of a file upload operation.
     *
     * If the operation has timed out, the status message is 'timed out'.
     * Otherwise, the status message is the human-readable representation of the status of the operation.
     *
     * Returns an array of strings, containing
     * - the number of files in the operation
     * - the human-readable representation of the status of the operation
     * - the human-readable title of the stage of the operation
     *
     * @param {boolean} timedOut - Whether the operation has timed out.
     * @param {object} lastUpdate - The last update message sent from the worker.
     * @param {Array} files - The list of files in the operation.
     * @returns {Array} The human-readable representation of the status of the file upload operation.
     */
    const getHrStatusDetails = (timedOut, lastUpdate, files) => {
        if (!files || files?.length === 0) {
            return [];
        }
        const statusMsg = timedOut ? 'timed out' : getStatusHr(lastUpdate.status)
        const hrStatus = `${files.length} filer`;
        const hrStatusTitle = getStageHrTitle(lastUpdate.id);
        const hrStatusMsg = statusMsg;

        const hrStatusDetails = [];
        hrStatusDetails.push(hrStatus);
        if (hrStatusTitle) {
            hrStatusDetails.push(hrStatusTitle)
        }
        if (hrStatusMsg) {
            hrStatusDetails.push(hrStatusMsg);
        }
        return hrStatusDetails;
    };

    /**
     * Handles the case when a file upload operation times out.
     *
     * When a file upload operation times out, this function is called. It logs an error message to the console,
     * indicating that the operation timed out, and displays a snackbar with an error message.
     *
     * @function handleFileUploadTimeout
     */
    const handleFileUploadTimeout = (jobId, lastUpdate) => {
        clientLog('error', `${jobId} ${lastUpdate.id} timed out`, 'fileuploadoperation');
        showSnackbar({
            dispatch: snackbarDispatch,
            title: 'Filopplasting',
            msg: 'Filopplastingen tar for lang tid - avbrutt',
            msgType: 'error'
        });
    };

    /**
     * Handles the case when a file upload operation fails.
     *
     * When a file upload operation fails, this function is called with the job ID and the last update message.
     * The function logs an error message to the console, indicating that the operation failed, and displays a snackbar with an error message.
     *
     * @param {object} o - The operation that failed.
     * @param {object} lastUpdate - The last update message sent from the worker.
     * @param {object} details - The details of the failure.
     */
    const handleFileUploadError = (o, lastUpdate, details) => {
        const {jobId} = o;
        clientLog('error', `${jobId} ${lastUpdate.id} failed: ${JSON.stringify(details)}`, 'fileuploadoperation');
        showSnackbar({
            dispatch: snackbarDispatch,
            title: 'Filopplasting',
            msg: 'Det oppstod en ukjent feil',
            msgType: 'error'
        });
        cancelOperation(appDispatch, o, (cancelledOperation) => {
            clientLog('info',
                `operation: ${cancelledOperation['jobId']} - cancelled due to an error`,
                'operationspoppernew');
        });
    };

    /**
     * Returns a list of JSX elements representing the file upload operations that are
     * in progress. Each element is a box that contains the number of files in the
     * operation, the status of the operation, and a progress bar representing the
     * progress of the operation. If the operation has timed out, a 'Lukk' button is
     * displayed. If the operation is complete, a 'Se igjennom' button is displayed.
     *
     * @returns {Array} A list of JSX elements representing the file upload operations
     * that are in progress.
     */
    const getFileUploadOperations = () => {
        if (!operations || operations.length === 0) {
            return [];
        }

        const fileUploadOperations = getOperationsByType(operations, 'FILE_UPLOAD');
        if (fileUploadOperations.length === 0) {
            return;
        }

        return <Box sx={{marginTop: '8px'}}>
            <Typography variant={"caption"} sx={{marginBottom: '4px'}}>Opplasting</Typography>
            {fileUploadOperations.map(o => {
                let allDone;
                const {jobId, files, workerMessages} = o;
                const lastUpdate = getOperationLastUpdate(operations, workerMessages);
                const {timedOut = false, details = {}, status, id} = lastUpdate;
             
                if (timedOut) {
                    handleFileUploadTimeout(jobId, lastUpdate);
                }

                if (status === 'error') {
                    handleFileUploadError(o, lastUpdate, details);
                }

                const hrStatusDetails = getHrStatusDetails(timedOut, lastUpdate, files);
                const progress = getProgress(files, lastUpdate);
                const extractMetadata = o.extractMetadata;

                if (!extractMetadata) {
                    allDone = progress === 100 && status === 'done' && id === 'gettingobjectstatus';
                } else {
                    allDone = progress === 100 && status === 'done' && id === 'extractingmetadata';
                }

                return <Box key={`upload-operation-${jobId}`} sx={{borderBottom: 'solid 1px #bbb', padding: '8px'}}>
                    <Box>
                        {hrStatusDetails.join(' ')}
                    </Box>
                    <Box>
                        <LinearProgress variant="determinate" value={progress}/>
                    </Box>
                    <Box>
                        <FileConversionStatus conversionDetails={lastUpdate}/>
                    </Box>
                    <Box>
                        {timedOut && <Button key={`close-${jobId}`}
                                             onClick={() => handleErrorClick(o)}>Lukk</Button>}
                        {allDone && <Button key={`view-all-files-${jobId}`}
                                            disabled={viewedFileOperations.includes(jobId)}
                                            onClick={() => handleViewAllFilesClick(o, lastUpdate)}>Se igjennom</Button>}
                    </Box>
                </Box>
            })}
        </Box>
    };

    /**
     * Check if at least one file upload operation is complete. If so, display list of operations.
     *
     * This function goes through all file upload operations and checks if any of them is complete.
     * If at least one is complete, then the list of operations is displayed.
     *
     * @returns {undefined}
     */
    const atLeastOneFileUploadOperationDone = useCallback(() => {
        if (!operations || operations.length === 0) {
            return;
        }
        const fileUploadOperations = getOperationsByType(operations, 'FILE_UPLOAD');
        if (fileUploadOperations.length === 0) {
            return;
        }

        let done = false;
        for (const o of fileUploadOperations) {
            if (done) {
                // Already done, exit loop.
                break;
            } else {
                // Figure out if operation is done.
                if (o.workerMessages) {
                    const lastMessage = o.workerMessages.at(-1);
                    if (!lastMessage) {
                        continue;
                    }
                    const {status, id} = lastMessage;
                    if (!o.extractMetadata) {
                        done = done || (status === 'done' && id === 'gettingobjectstatus');
                    } else if (o.extractMetadata) {
                        done = done || (status === 'done' && id === 'extractingmetadata');
                    }
                }
            }
        }

        // Display list of operations, when at least one file upload operation is complete.
        if (done && !showActiveOperations) {
            setShowActiveOperations(true);
        }
    }, [operations, showActiveOperations]);

    /**
     * Hook used to update the list of active operations.
     */
    useEffect(() => {
        if (!operations || operations.length === 0) {
            return;
        }
        atLeastOneFileUploadOperationDone();
        setActiveOperations(operations);
    }, [operations, atLeastOneFileUploadOperationDone]);

    // When worker messages are present for one or more operations, display the information box,
    // otherwise hide it!
    return isWorkerMessagesPresent() &&
        <Box
            sx={{
                backgroundColor: 'white',
                border: 'solid 1px #bbb',
                borderRadius: '0px 0px 4px 4px',
                padding: '8px',
                cursor: 'pointer',
                position: 'absolute',
                top: '64px',
                right: '39px',
                zIndex: 999,
                minWidth: '300px'
            }}
            onClick={toggleOperationsList}>
            <Box>
                Aktive operasjoner: {activeOperations.length}
            </Box>
            {showActiveOperations && getFileUploadOperations()}
            <DialogListFiles/>
        </Box>;
};