import React, {useEffect, useRef, useState} from "react";
import {Box, Toolbar} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import {useAuthsState} from "../auths/authsContext";
import {resurrectFileUploadOperations} from "../operations/ressurect/resurrectFileUploadOperations";
import {useAppDispatch} from "./AppContext";

const useStyles = makeStyles(() => ({
    root: {
        width: "100dvw",
        height: "100dvh",
        display: "flex",
        flexDirection: "column",
        alignItems: 'stretch',
        justifyContent: 'stretch',
    },
    container: {
        //  height: (props) => `calc(100% - ${props.toolbarHeight / window.innerHeight * 100}%)`,
        //  minHeight: (props) => `calc(100% - ${props.toolbarHeight / window.innerHeight * 100}%)`,
        overflow: 'hidden', // Layout changed to have separeate overflow scrollbars in results and filters       
        display: 'flex',
        alignItems: 'stretch',
        justifyContent: 'stretch',
        flexGrow: '1',
    },
}));

export const AppContent = ({children}) => {
    const toolbarRef = useRef();
    const [toolbarHeight, setToolbarHeight] = useState(64);

    const appDispatch = useAppDispatch();
    const {userIsAuthenticated, userData, museumCollections} = useAuthsState();

    const classes = useStyles({
        toolbarHeight: toolbarHeight,
    });

    useEffect(() => {
        if (!userIsAuthenticated || !userData || !museumCollections || museumCollections.length === 0) {
            return;
        }
        resurrectFileUploadOperations(userIsAuthenticated, userData, museumCollections, appDispatch).then();
    }, [userIsAuthenticated, userData, museumCollections, appDispatch]);

    useEffect(() => {
        if (toolbarRef.current) {
            setToolbarHeight(toolbarRef.current['clientHeight']);
        }
    }, [setToolbarHeight]);

    return (
        <div className={classes.root}>
            <Toolbar ref={toolbarRef}/>
            <Box className={classes.container}>
                {children}
            </Box>
        </div>
    );
};
