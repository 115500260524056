import React, {Suspense, useEffect, useState} from "react";
import {useSearch} from "./useSearch";
import {ResultCard} from "./resultcard/ResultCard";
import {ResultGrid} from "./ResultGrid";
import {GridViewButton} from "./GridViewButton";
import {SearchResultsOrderByMenu} from "./SearchResultsOrderByMenu";
import {FilterSearch} from "./filters/FilterSearch";
import {
    SET_ROWS,
    SET_SEARCHING,
    SET_SORT,
    SET_START,
    useSearchDispatch,
    useSearchState,
    useSearchTranslation,
} from "./SearchContext";
import {ResultTable} from "./ResultTable";
import {TableViewButton} from "./TableViewButton";
import {SearchResultsActions} from "./SearchResultsActions";
import {useMuseumState} from "../museum/MuseumContext";
import {useSnackbarDispatch} from "../snackbar/SnackbarContext";
import Box from "@mui/material/Box";
import ButtonGroup from "@mui/material/ButtonGroup";
import Divider from "@mui/material/Divider";
import Paper from "@mui/material/Paper";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import {FolderSearchSettings} from "./FolderSearchSettings";
import {SearchSettings} from "./SearchSettings";
import {ResultTableProvider} from "./ResultTableContext";
import LinearProgress from "@mui/material/LinearProgress";
import {clientLog} from "../clientLog";
import {getDefaultDocumentTypesConfig, useDocumentTypeState} from "../document-type/documentTypeContext";
import {useMyMuseums} from "../museum/useMyMuseums";
import TablePagination from "@mui/material/TablePagination";
import {ThemeProvider} from "@mui/styles";
import {createTheme} from "@mui/material";
import * as locales from "@mui/material/locale";
import i18next from "i18next";
import {NoHits} from "./NoHits";
import {showSnackbar} from "../app/showSnackbar";


const SearchResultDetails = React.lazy(() => import("../search-result-details/SearchResultDetails"));

const componentName = 'SearchResults';

/**
 * Renders the search results component.
 *
 * @param {Object} folder - The folder object.
 * @return {JSX.Element} The search results component.
 */
export const SearchResults = ({folder}) => {

    const t = useSearchTranslation();
    const searchDispatch = useSearchDispatch();

    const {museums} = useMuseumState();
    const myMuseums = useMyMuseums({onlyAdministrativeMuseums: false});

    const snackbarDispatch = useSnackbarDispatch();

    const documentTypeState = useDocumentTypeState();

    const {
        timestamp,
        query,
        fl,
        start,
        expand,
        facetField,
        statsField,
        fq,
        results,
        selected,
        triggerSearch,
        onlyMine,
        searching,
    } = useSearchState();

    const searchSettings = SearchSettings('dams.searchResults.config');
    const folderSearchSettings = FolderSearchSettings('dams.folderSearchResults.config');

    const [locale] = useState(i18next.language === "nbNO" ? "svSE" : "enUS");

    const currentNumRows = !folder?.id ? searchSettings.getNumberOfRows() : folderSearchSettings.getNumberOfRows();

    const [viewMode, setViewMode] = useState(
        !folder?.id
            ? searchSettings.getDisplayType()
            : folderSearchSettings.getDisplayType()
    );

    /**
     * Handles the change of the page in search results.
     *
     * @param {_event} - the event triggering the page change
     * @param {number} newPage - the new page number
     * @return {void}
     */
    const handleChangePage = (_event, newPage) => {
        searchDispatch({
            type: SET_START,
            start: newPage * currentNumRows,
        });
        searchDispatch({
            type: SET_SEARCHING
        });
    };

    /**
     * Handles the change event for the number of rows per page.
     *
     * @param {Event} event - The event object triggering the change.
     * @return {void} No return value.
     */
    const handleChangeRowsPerPage = (event) => {
        const value = event.target['value'];

        !folder?.id
            ? searchSettings.setNumberOfRows(value)
            : folderSearchSettings.setNumberOfRows(value);

        searchDispatch({
            type: SET_ROWS,
            rows: value,
        });

        searchDispatch({
            type: SET_SEARCHING
        });
    };

    /**
     * Returns a sanitized string containing the selected document types to include as filter in the current search.
     * @returns {*}
     */
    const sanitizedDocumentTypes = () => {
        let documentTypes = documentTypeState.documentTypes;
        if (documentTypeState.documentTypes === '') {
            documentTypes = getDefaultDocumentTypesConfig(true)
        } else {
            documentTypes = documentTypes.replace('OR Prosjekter', '')
        }
        return documentTypes;
    };

    const page = start / currentNumRows;

    const sort = !folder?.id
        ? searchSettings.getSortCriteria()
        : folderSearchSettings.getSortCriteria();

    const museumsCriteria = museums.length > 0 ? museums : myMuseums.map(mm => mm.id);

    const criteria = {
        timestamp: timestamp,
        query: query,
        sort: `${sort}`,
        fl: fl,
        start: start,
        rows: currentNumRows,
        expand: expand,
        documentType: sanitizedDocumentTypes(),
        facetField: facetField,
        statsField: statsField,
        fq: fq,
        museums: museumsCriteria,
        triggerSearch,
        onlyMine: onlyMine,
    };

    useSearch(criteria);

    /**
     * A function that handles the change of view mode.
     *
     * @param {type} newViewMode - The new view mode to set.
     * @return {void} No return value.
     */
    const onTypeChange = (newViewMode) => {
        !folder?.id
            ? searchSettings.setDisplayType(newViewMode)
            : folderSearchSettings.setDisplayType(newViewMode);
        setViewMode(newViewMode);
    };

    const RESULT_MODE = {
        grid: (
            <ResultGrid folderId={folder?.id}>
                {results.models.map((model) => {
                    return <ResultCard
                        model={model}
                        key={model.id}
                        selected={Boolean(
                            selected.find(
                                (selectedModel) => selectedModel.uniqueId === model.uniqueId
                            )
                        )}
                    />;
                })}
            </ResultGrid>
        ),
        table: <ResultTableProvider><ResultTable folderId={folder?.id}
                                                 dataRows={results.models}/></ResultTableProvider>,
    };

    if (results.error) {
        clientLog('error', JSON.stringify(results.error), componentName);
        showSnackbar(snackbarDispatch,
            t("searchFailedErrorTitle", "Søk feilet"),
            t("searchFailedErrorMessage", "Har du valgt dokumenttype?"),
            "error");
    }

    // eslint-disable-next-line no-unused-vars
    const [displayCount, setDisplayCount] = useState(results.count);

    // UseEffect hook to update displayCount based on conditions
    useEffect(() => {
        // Update displayCount only when not searching
        if (!searching) {
            setDisplayCount(results.count);
        }
    }, [results.count, searching, viewMode]);

    // useEffect for NoHits
    useEffect(() => {
        if (!searching && results.count <= 0 && results.models.length === 0) {
            setDisplayCount(0);
        }
    }, [searching, results.count, results.models.length]);

    return (
        <Paper sx={{
            flexGrow: 1,
            overflow: 'hidden',
            display: 'flex',
            alignItems: 'stretch',
            justifyContent: 'stretch',
            margin: {
                xs: 0,
                xl: '0 2.5rem 2.75rem 2.5rem',
            },
        }}>
            <Suspense fallback={<></>}>
                <SearchResultDetails/>
            </Suspense>
            <Box sx={{display: 'flex', flexDirection: 'row', overflow: 'hidden', flexGrow: 1}}>
                <Box sx={{flexGrow: 1, overflow: 'hidden', display: 'flex', flexDirection: 'column'}}>
                    <Toolbar sx={{
                        display: 'flex',
                        flexShrink: 1,
                        flexWrap: 'wrap',
                        gap: '.25rem',
                        alignItems: 'center',

                        "&.MuiToolbar-root": {
                            paddingLeft: 2,
                            paddingRight: 1,
                            minHeight: 'auto',
                        }


                    }}>
                        <Typography variant="h5" component="h2" paddingBlock={'.75rem'} id={"search-results-title"}
                                    sx={{
                                        paddingRight: .5,
                                    }}>
                            {onlyMine
                                ? t("searchResultsMyFiles", "Mine filer")
                                : t("searchResults", "Søkeresultat")}
                        </Typography>
                        <Box sx={{display: {xs: 'none', md: 'block'}}}>
                            <ButtonGroup>
                                <TableViewButton onToggle={onTypeChange} viewMode={viewMode}/>
                                <GridViewButton onToggle={onTypeChange} viewMode={viewMode}/>
                            </ButtonGroup>
                        </Box>
                        <Box flexGrow={1}/>

                        <Box sx={{
                            display: 'flex',
                            alignItems: 'center',
                            minHeight: {
                                xs: "auto",
                                md: '4rem',
                            },
                        }}>
                            <SearchResultsActions folder={folder}/>
                            <Box sx={{display: {xs: 'none', md: 'block'}}}>
                                <SearchResultsOrderByMenu
                                    sort={sort}
                                    t={t}
                                    selectHandler={(value) => {
                                        searchDispatch({
                                            type: SET_SORT,
                                            sort: value,
                                        });
                                        setTimeout(() => {
                                            searchDispatch({type: SET_SEARCHING});
                                        }, 500);
                                    }}
                                />
                            </Box>
                        </Box>
                        <ThemeProvider
                            theme={createTheme({}, locales[locale])}
                        >

                            <TablePagination
                                sx={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    "& .MuiTablePagination-toolbar": {
                                        paddingLeft: 0,
                                        paddingRight: 0,
                                    },
                                    "& .MuiTablePagination-input": {
                                        marginLeft: 0,
                                        marginRight: 0.5,
                                    },
                                    "& div.MuiTablePagination-actions": {
                                        marginLeft: .5,
                                    }
                                }}
                                rowsPerPageOptions={[5, 10, 25, 100]}
                                component="div"
                                count={displayCount}
                                rowsPerPage={currentNumRows}
                                page={page}
                                labelRowsPerPage={t('show', 'Vis')}
                                onPageChange={handleChangePage}
                                onRowsPerPageChange={handleChangeRowsPerPage}
                                labelDisplayedRows={({from, to, count}) =>
                                    t("labelDisplayedRows", "{{from}}-{{to}} av {{count}}", {
                                        from: from,
                                        to: to,
                                        count: count,
                                    })
                                }
                            />
                        </ThemeProvider>
                    </Toolbar>
                    <Divider/>

                    <Box id="results-area" sx={{
                        padding: 0,
                        margin: 0,
                        overflow: 'hidden',
                        display: 'flex',
                        flexGrow: 1,
                        flexShrink: 1,
                    }}>
                        {searching && <LinearProgress sx={{maxWidth: '100%', width: '100%'}} color={"secondary"}/>}
                        {!searching && results.models.length > 0 && RESULT_MODE[viewMode]}
                        {!searching && results.count <= 0 && results.models.length === 0 && <NoHits/>}
                    </Box>

                </Box>
                <Box sx={{display: {xs: 'none', md: 'flex'}}}>
                    <Divider
                        orientation="vertical"
                        flexItem
                    />
                    {/* Flex child for the filters: */}
                    <Box sx={{
                        padding: 0,
                        margin: 0,
                        minWidth: {
                            sm: '25rem',
                            xl: '29rem',
                        },
                        maxWidth: {
                            sm: '25rem',
                            xl: '29rem',
                        },
                        flexBasis: {
                            sm: '25rem',
                            xl: '29rem',
                        },
                        flexShrink: 0,
                        display: 'flex'
                    }}>
                        <FilterSearch/>
                    </Box>
                </Box>
            </Box>
        </Paper>
    );
};
