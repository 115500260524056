import {useEffect, useState} from "react";
import Box from "@mui/material/Box";
import ErrorIcon from '@mui/icons-material/Error';

/**
 * Component that renders the status of a file conversion operation.
 *
 * Renders a list of documents/files that failed conversion.
 *
 * @param {{conversionDetails: {id: string, details: {failed: Array, success: Array}}}} props
 * @returns {JSX.Element}
 */
export const FileConversionStatus = ({conversionDetails}) => {
    const [failed, setFailed] = useState(0);
    const [documents, setDocuments] = useState({});

    const renderStatus = document => {
        return <Box key={`status-container-${document['dms_id']}`}
                    sx={{
                        marginTop: '8px',
                        marginBottom: '8px',
                        paddingBottom: '8px',
                    }}>
            {['fail', 'failed', 'error'].includes(document.status) &&
                <Box sx={{
                    textOverflow: 'ellipsis',
                    overflow: 'hidden',
                    textWrapMode: 'nowrap',
                    borderBottom: 'solid 1px #ddd',
                }}>
                    <ErrorIcon sx={{marginRight: '8px', color: '#ff8585'}}/>
                    {document.filename}
                </Box>
            }
        </Box>;
    };

    /**
     * Renders a box containing the status of all documents in `documents`.
     *
     * Each document is rendered with a call to `renderStatus`.
     *
     * @returns {JSX.Element} A box containing the status of all documents.
     */
    const renderDocuments = () => {
        return <Box
            sx={{
                overflowY: 'auto',
                maxHeight: '60vh',
                maxWidth: '400px',
                padding: '8px'
            }}>
            {
                failed > 0
                && <Box sx={{
                    padding: '4px',
                    color: 'red',
                    margin: '4px'
                }}>
                    Merk: Konvertering feilet for {failed} av {documents.length} filer.
                </Box>
            }
            {documents.map(d => (renderStatus(d)))}
        </Box>;
    };

    useEffect(() => {
        if (!conversionDetails) {
            return;
        }

        const {id, details} = conversionDetails;
        if (!id || !details) {
            return;
        }

        if (!Object.keys(details).includes('failed') || !Object.keys(details).includes('success')) {
            return;
        }

        if (Array.isArray(details?.failed) && Array.isArray(details?.success)) {
            setDocuments([...details?.failed, ...details?.success]);
            setFailed(details?.failed.length);
        } else if (Array.isArray(details?.failed) && !Array.isArray(details?.success)) {
            setDocuments(details?.failed);
            setFailed(details?.failed.length);
        } else if (!Array.isArray(details?.failed) && Array.isArray(details?.success)) {
            setDocuments(details?.success);
        }
    }, [conversionDetails]);


    return documents.length > 0 && renderDocuments();
};