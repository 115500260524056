import {loadIncompleteOperations} from "./loadIncompleteOperations";
import {createResurrectedFileOperationObject} from "./createResurrectedFileOperationObject";
import {executeApiRequest} from "../executeApiRequest";
import {ADD_OPERATION, UPDATE_OPERATION} from "../../app/AppContext";
import {kickOffUploadWorker} from "../../webworkers/fileuploads/kickOffUploadWorker";
import {getWorkerConfigBase} from "../../webworkers/getWorkerConfigBase";
import {scheduleOperation} from "../scheduleOperation";
import {registerOperationInDb} from "../registerOperationInDb";
import {damsFetch} from "../../app/damsFetch";
import decamelizeKeysDeep from "decamelize-keys-deep";
import {clientLog} from "../../clientLog";

/**
 * Loads incomplete operations from the server and adds them to the state.
 *
 * @param {boolean} userIsAuthenticated - Is the user authenticated?
 * @param {Object} userData - User data object containing the user ID and other
 *   information.
 * @param {Object[]} museumCollections - List of objects containing the
 *   collection ID of each museum collection.
 * @param {Function} appDispatch - The dispatch function for the app state.

 * @throws {Error} If userIsAuthenticated is false.
 * @throws {Error} If userData is not specified.
 * @throws {Error} If museumCollections is not specified or has no values.
 * @throws {Error} If appDispatch is not specified.
 *
 * @return {Promise<void>} A promise that resolves when the operations have been
 *   loaded and added to the state.
 */
export const resurrectFileUploadOperations = async (userIsAuthenticated,
                                                    userData,
                                                    museumCollections,
                                                    appDispatch) => {
    if (!userIsAuthenticated) {
        throw Error('User must be authenticated');
    }

    if (!userData || Object.keys(userData).length === 0) {
        throw Error('userData is not specified');
    }

    if (!museumCollections || museumCollections?.length === 0) {
        throw Error('museumCollections is not specified or has no values');
    }

    if (!appDispatch) {
        throw Error('appDispatch is not specified');
    }

    const data = await loadIncompleteOperations(museumCollections);
    if (!data || data?.length === 0) {
        return;
    }

    const resurrectableOperations = _getResurrectableOperations(data, museumCollections);
    if (resurrectableOperations?.length === 0) {
        return;
    }

    clientLog('info', `found ${resurrectableOperations.length} resurrectable operations`);

    let resurrectedOperations = [];

    // Create a new operation, that will replace the resurrected operation:
    for (let i = 0, max = resurrectableOperations?.length; i < max; i++) {
        const resurrectableOperation = resurrectableOperations[i];
        const oldJobId = resurrectableOperation.jobId;
        const {files} = resurrectableOperation;

        const registeredOperation = await _registerOperation(appDispatch, resurrectableOperation);
        const rescheduledOperation =
            await _appendFilesAndRescheduleOperation(appDispatch, registeredOperation, files);

        // Update the local list of resurrected operations:
        resurrectedOperations.push(rescheduledOperation);

        // Set the origin operation as "cancelled":
        await _markOperationAsCancelled({...resurrectableOperation, 'jobId': oldJobId});

        const apiGateway = window._env_.REACT_APP_DAMS_ADMIN_API;
        kickOffUploadWorker({
            workerConfig: getWorkerConfigBase(apiGateway),
            operationId: rescheduledOperation.jobId,
            operations: resurrectedOperations.filter(o => o.jobType === 'FILE_UPLOAD'),
            uploadWorkerDispatch: appDispatch
        });
    }
};

/**
 * Sends a request to cancel the specified operation and updates its status in the database.
 *
 * @param {object} operation - The operation to be marked as cancelled.
 *
 * @returns {Promise<void>} A promise that resolves when the operation has been marked as cancelled.
 */
const _markOperationAsCancelled = async (operation) => {
    await damsFetch('/jobs/file-upload', {
        method: 'POST',
        body: JSON.stringify(decamelizeKeysDeep({
            action: 'cancel',
            operation: operation
        }))
    });
};

/**
 * Registers a resurrected operation in the DAMS database, schedules the operation,
 * and adds the operation to the list of operations on the client.
 *
 * @param {function} appDispatch - Dispatch function to update the list of operations on the client.
 * @param {object} resurrectableOperation - Object containing the details of the operation to be resurrected.
 *
 * @returns {Promise<object>} The promise that resolves with the registered operation object.
 *
 * @private
 */
const _registerOperation = async (appDispatch, resurrectableOperation) => {
    if (resurrectableOperation.jobId) {
        delete resurrectableOperation.jobId;
    }

    if (resurrectableOperation.jobType) {
        delete resurrectableOperation.jobType;
    }

    if (resurrectableOperation.files) {
        delete resurrectableOperation.files;
    }

    if (resurrectableOperation.workerMessages) {
        delete resurrectableOperation.workerMessages;
    }

    const res = await scheduleOperation(resurrectableOperation);
    const {operation: scheduledOperation} = res;

    // Register the operation in the DAMS database, to track its progress:
    const registered = await registerOperationInDb(scheduledOperation, resurrectableOperation);

    // Add the operation to the list of operations on the client:
    appDispatch({
        type: ADD_OPERATION,
        operation: registered
    });

    return registered;
};

/**
 * Appends the given list of files to the registered operation, and reschedules it.
 *
 * @param {function} appDispatch - The dispatch function of the app state.
 * @param {object} registeredOperation - The operation that has been registered.
 * @param {array<object>} files - The list of files to append to the operation.
 * @returns {Promise<object>} A promise that resolves with the rescheduled operation.
 */
const _appendFilesAndRescheduleOperation = async (appDispatch, registeredOperation, files) => {
    // Append the list of files to the operation, and reschedule it:
    const operation = {...registeredOperation, files};
    await executeApiRequest('add-files', operation)
    await executeApiRequest('modify', operation);

    // Update the operation on the client, with the new list of files:
    appDispatch({
        type: UPDATE_OPERATION,
        operation: operation
    });

    const rescheduled = await executeApiRequest('reschedule', operation);

    // Update the operation on the client, with the rescheduled operation:
    appDispatch({
        type: UPDATE_OPERATION,
        operation: rescheduled.operation
    });

    return rescheduled.operation;
};

/**
 * Creates an array of resurrectable operations from the given data and museum collections.
 *
 * @param {object[]} data An array of objects, each containing a job ID, collection ID, status and message of an operation.
 * @param {object[]} museumCollections An array of objects, each containing the collection ID and museum ID of a museum collection.
 *
 * @returns {object[]} An array of resurrectable operations, each with a jobId, collectionId, museumId and files property.
 */
const _getResurrectableOperations = (data, museumCollections) => {
    const operationObjects = [];
    for (let i = 0, max = data?.length; i < max; i++) {
        const operationObj = createResurrectedFileOperationObject(museumCollections, data[i]);
        if (operationObj) {
            operationObjects.push(operationObj);
        }
    }
    return operationObjects;
};

