import {NOTIFY_MULTIPLE_FROM_WORKER, UPLOAD_WORKER_UNKNOWN_ERROR, useAppDispatch} from "../../app/AppContext";
import {useCallback, useEffect} from "react";
import {clientLog} from "../../clientLog";

/**
 * Listener used to listen for, and parse, messages sent by the upload web worker.
 *
 * @return {JSX.Element} An empty JSX element.
 */
export const UploadWorkerListener = () => {
    const appDispatch = useAppDispatch();

    const isValidMessage = event => {
        if (!event) {
            return;
        }

        if (!event.data) {
            return false;
        }

        const {channel, action} = event.data;
        return !(!channel || channel !== 'client' || !action || action === '');
    };

    const workerMessageParser = useCallback((event) => {
        if (!isValidMessage(event)) {
            return;
        }

        const data = event.data;
        const {action} = data
        const {data: statusData} = data;

        if (action === 'statusUpdate') {
            clientLog('debug', `uploadworker statusupdate: ${JSON.stringify(event.data)}`, 'uploadworker');
            // Notify the GUI about file upload status changes.
            appDispatch({
                type: NOTIFY_MULTIPLE_FROM_WORKER,
                messages: statusData
            });
        } else if (action === 'error') {
            clientLog('error', `uploadworker error: ${JSON.stringify(event.data.message)}`, 'uploadworker');
            appDispatch({type: UPLOAD_WORKER_UNKNOWN_ERROR});
        }
    }, [appDispatch]);

    /**
     * Hook that listens for messages from the upload worker.
     */
    useEffect(() => {
        window._uploadWorkerInstance.onmessage = workerMessageParser;
    }, [workerMessageParser]);

    return <></>;
};